import React, { Component } from 'react';

import Layout from 'src/components/layout';
import Disclaimer from '../../components/disclaimer';

import { AppStateContext } from 'src/contexts/app-state-provider.context';
import { TranslatedBlock } from 'src/components/language';
import { TranslatableText } from '../../components/language';

import RiskminIStock from '../../images/riskmin-iStock.png';
import AudioIcon from '../../images/audio-icon.svg';
import PatientCareGiver from '../../images/patientcaregiver.svg';
import ArrowDown from '../../images/arrow-down.svg';

import './index.scss';

class PatientPage extends Component {
  static contextType = AppStateContext;
  state = {
    isAudioVisible: false,
    isDropdownVisible: false,
    isTranscriptSectionVisible: false,
    guideType: null,
  };

  guideDictionary = {
    nAMD: {
      english: {
        name: 'nAMD',
        title: 'nAMD Patient/Caregiver Guide',
        audio: 'VABYSMO_RiskMin_PatientCaregiverGuide_wetAMD_Audio_File-86779E24v.mp3',
        pdf: 'VABYSMO_RiskMin_PatientCaregiverGuide_wetAMD_EN.pdf',
      },
      french: {
        name: 'DMLAn',
        title: 'Guide du patient/soignant sur la DMLAn',
        audio: 'VABYSMO_RiskMin_PatientCaregiverGuide_wetAMD_Audio_File-86779F24v.mp3',
        pdf: 'VABYSMO_RiskMin_PatientCaregiverGuide_wetAMD_FR.pdf',
      },
    },
    DME: {
      english: {
        name: 'DME',
        title: 'DME Patient/Caregiver Guide',
        audio: 'VABYSMO_RiskMin_PatientCaregiverGuide_DME_Audio_File-86780E24v.mp3',
        pdf: 'VABYSMO_RiskMin_PatientCaregiverGuide_DME_EN.pdf',
      },
      french: {
        name: 'OMD',
        title: 'Guide du patient/soignant sur l’OMD',
        audio: 'VABYSMO_RiskMin_PatientCaregiverGuide_DME_Audio_File-86780F24v.mp3',
        pdf: 'VABYSMO_RiskMin_PatientCaregiverGuide_DME_FR.pdf',
      },
    },
    RVO: {
      english: {
        name: 'Macular edema secondary to RVO',
        title: 'Macular edema secondary to RVO Patient/Caregiver Guide',
        audio: 'VABYSMO_RiskMin_PatientCaregiverGuide_RVO_Audio_File-86781E24v.mp3',
        pdf: 'VABYSMO_RiskMin_PatientCaregiverGuide_RVO_EN.pdf',
      },
      french: {
        name: 'Œdème maculaire secondaire à une OVR',
        title: 'Guide du patient/soignant sur l’œdème maculaire secondaire à une OVR',
        audio: 'VABYSMO_RiskMin_PatientCaregiverGuide_RVO_Audio_File-86781F24v.mp3',
        pdf: 'VABYSMO_RiskMin_PatientCaregiverGuide_RVO_FR.pdf',
      },
    },
  };

  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('risk-management-measures');
  }

  toggleDropdown = () => {
    this.setState({ isDropdownVisible: !this.state.isDropdownVisible });
  };

  setGuideType = guideType => {
    this.setState({
      guideType,
      isAudioVisible: false,
      isDropdownVisible: false,
    });
  };

  setNAMDGuideType = () => {
    this.setGuideType('nAMD');
  };

  setDMEGuideType = () => {
    this.setGuideType('DME');
  };

  setRVOGuideType = () => {
    this.setGuideType('RVO');
  };

  render() {
    const title = {
      english: 'Safety Information',
      french: 'Mesures d’atténuation des risques',
    };
    const guideType = this.state.guideType;
    const guideTitle = this.guideDictionary[guideType]
      ? this.guideDictionary[guideType][this.context.language].title
      : '';
    const guideName = this.guideDictionary[guideType]
      ? this.guideDictionary[guideType][this.context.language].name
      : this.context.language === 'french'
      ? 'Sélectionnez'
      : 'Select';
    const guideAudio = this.guideDictionary[guideType]
      ? this.guideDictionary[guideType][this.context.language].audio
      : '';
    const guidePdf = this.guideDictionary[guideType] ? this.guideDictionary[guideType][this.context.language].pdf : '';
    return (
      <Layout title={title} showNavbar={true} className="patient">
        <section className="page-header">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-12 col-md-5 risk-minimization py-lg-0 py-5">
                <TranslatedBlock language="english">
                  <h1>Risk Management Measures</h1>
                  <h2>Safety Information</h2>
                </TranslatedBlock>
                <TranslatedBlock language="french">
                  <h1>Mesures d’atténuation des risques</h1>
                  <h2>Renseignements sur l’innocuité</h2>
                </TranslatedBlock>
              </div>
              <div className="col-12 col-md-7 patient-guide">
                <img src={RiskminIStock} alt="Risk Management Measures Icon" />
              </div>
            </div>
          </div>
        </section>

        <div className="container pb-2">
          <div className="row">
            <div className="col-12 py-5 text-center">
              <h2 className="h1 mb-0">
                <TranslatableText
                  dictionary={{
                    english: 'VABYSMO Patient/Caregiver Guide(s)',
                    french: 'Guide(s) du patient/soignant sur VABYSMO',
                  }}
                />
              </h2>
            </div>
          </div>
        </div>

        <section className="risk-management-measures-download-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-4 py-5">
                <p className="text-white text-lg-left text-center">
                  <TranslatableText
                    dictionary={{
                      english: 'Select your VABYSMO Patient/Caregiver Guide',
                      french: 'Sélectionnez votre Guide du patient/soignant sur VABYSMO.',
                    }}
                  />
                </p>
                <div className="dropdown-wrapper">
                  <button type="button" className="dropdown-button" onClick={this.toggleDropdown}>
                    {guideName}
                    <span>
                      <img src={ArrowDown} alt="Assets toggler" />
                    </span>
                  </button>
                  {this.state.isDropdownVisible && (
                    <div className="dropdown-select shadow">
                      <a onClick={this.setNAMDGuideType}>{this.guideDictionary.nAMD[this.context.language].name}</a>
                      <hr className="m-0" />
                      <a onClick={this.setDMEGuideType}>{this.guideDictionary.DME[this.context.language].name}</a>
                      <hr className="m-0" />
                      <a onClick={this.setRVOGuideType}>{this.guideDictionary.RVO[this.context.language].name}</a>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-8 py-5 px-0">
                <div className="row justify-content-center">
                  <div className="col-8 mb-4 text-center">
                    <h3>{guideTitle}</h3>
                  </div>
                </div>
                <div className={`row audio-listen ${this.state.guideType === null ? 'disabled' : ''}`}>
                  <div className="col-12 col-lg-6 pdf">
                    <img src={PatientCareGiver} alt="Patient/Caregiver Guide Icon" className="pdf-img" />
                    <TranslatableText
                      dictionary={{
                        english: 'A pdf version of the guide',
                        french: 'Version PDF du guide',
                      }}
                    />
                    <a
                      href={`https://www.rochecanada.com/documents/vabysmo/${guidePdf}`}
                      className="pdf-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TranslatableText
                        dictionary={{
                          english: 'View here',
                          french: 'CONSULTER',
                        }}
                      />
                    </a>
                  </div>
                  <div className="col-12 col-lg-6 mt-5 mt-lg-0 audio">
                    <img src={AudioIcon} alt="Audio Icon" />
                    <TranslatableText
                      dictionary={{
                        english: 'An audio version of the guide',
                        french: 'Version audio du guide',
                      }}
                    />
                    <button
                      className="listen"
                      onClick={() => this.setState({ isAudioVisible: true })}
                      disabled={this.state.guideType === null ? true : false}
                    >
                      <TranslatableText dictionary={{ english: 'Listen Now', french: 'ÉCOUTER' }} />
                    </button>
                  </div>

                  <div className="col-12 col-lg-6 offset-lg-6 text-center buttons">
                    {this.state.isAudioVisible && (
                      <div className="audio-el">
                        {/* eslint-disable jsx-a11y/media-has-caption */}
                        <audio controls autoPlay src={`/audio/${guideAudio}`}>
                          Your browser does not support the
                          <code>audio</code> element.
                        </audio>
                        {/* eslint-enable jsx-a11y/media-has-caption */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Disclaimer />
      </Layout>
    );
  }
}

export default PatientPage;
